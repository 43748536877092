import { authHeader } from '../_helpers/auth-header';
import Resource from '../_helpers/resource';
import moment from 'moment';

const resource = new Resource(process.env.REACT_APP_API_URL);
const resourceBack = new Resource(process.env.REACT_APP_API_BASE);
console.log('resourceBack', resourceBack);

export const analyticsService = {
  integration: (userId, shopId) => {
    return resource.get(`user/${userId}/shop/${shopId}/analytics/integration/`, authHeader());
  },

  click: (userId, shopId, from, to, limit) => {
    let api = `user/${userId}/shop/${shopId}/analytics/click`;
    let queryString = new URLSearchParams();

    if (!!from) {
      queryString.set('from', from);
    }

    if (!!to) {
      queryString.set('to', to);
    }

    if (!!limit) {
      queryString.set('limit', limit);
    }

    api = `${api}/?${queryString.toString()}`;

    return resource.get(api, authHeader());
  },

  similarShopClick: (userId, shopId, from, to, limit) => {
    let api = `user/${userId}/shop/${shopId}/analytics/click/similar`;
    let queryString = new URLSearchParams();

    if (!!from) {
      queryString.set('from', from);
    }

    if (!!to) {
      queryString.set('to', to);
    }

    if (!!limit) {
      queryString.set('limit', limit);
    }

    api = `${api}?${queryString.toString()}`;

    return resource.get(api, authHeader());
  },

  averageClick: (from, to, limit) => {
    let api = `analytics/click/average`;
    let queryString = new URLSearchParams();

    if (!!from) {
      queryString.set('from', from);
    }

    if (!!to) {
      queryString.set('to', to);
    }

    if (!!limit) {
      queryString.set('limit', limit);
    }

    api = `${api}?${queryString.toString()}`;

    return resource.get(api, authHeader());
  },

  premiumShopClick: (from, to, limit) => {
    let api = `analytics/click/premium`;
    let queryString = new URLSearchParams();

    if (!!from) {
      queryString.set('from', from);
    }

    if (!!to) {
      queryString.set('to', to);
    }

    if (!!limit) {
      queryString.set('limit', limit);
    }

    api = `${api}?${queryString.toString()}`;

    return resource.get(api, authHeader());
  },

  products: (userId, shopId, from, to, limit) => {
    let api = `user/${userId}/shop/${shopId}/analytics/products`;
    let queryString = new URLSearchParams();

    if (!!from) {
      queryString.set('from', from);
    }

    if (!!to) {
      queryString.set('to', to);
    }

    if (!!limit) {
      queryString.set('limit', limit);
    }

    api = `${api}?${queryString.toString()}`;

    return resource.get(api, authHeader());
  },

  transactions: (userId, shopId, from, to, limit) => {
    let api = `user/${userId}/shop/${shopId}/analytics/transactions`;
    let queryString = new URLSearchParams();

    if (!!from) {
      queryString.set('from', from);
    }

    if (!!to) {
      queryString.set('to', to);
    }

    if (!!limit) {
      queryString.set('limit', limit);
    }

    api = `${api}?${queryString.toString()}`;

    return resource.get(api, authHeader());
  },

  transactionsTotals: (userId, shopId, from, to, limit) => {
    let api = `user/${userId}/shop/${shopId}/analytics/transactionsTotals`;
    let queryString = new URLSearchParams();

    if (!!from) {
      queryString.set('from', from);
    }

    if (!!to) {
      queryString.set('to', to);
    }

    if (!!limit) {
      queryString.set('limit', limit);
    }

    api = `${api}?${queryString.toString()}`;

    return resource.get(api, authHeader());
  },

  productstats: (code) => {
    let api = `products/${code}/stats`;
    let queryString = new URLSearchParams();

    api = `${api}?${queryString.toString()}`;

    return resource.get(api, authHeader());
  },

  producthistory: (shopId, code, from, to, limit) => {
    let api = `products/${shopId}/${code}/history`;
    let queryString = new URLSearchParams();

    if (!!from) {
      queryString.set('from', from);
    }

    if (!!to) {
      queryString.set('to', to);
    }

    if (!!limit) {
      queryString.set('limit', limit);
    }

    api = `${api}?${queryString.toString()}`;

    return resource.get(api, authHeader());
  },

  productPreviousPrice: (shopId, body) => {
    let api = `products/${shopId}/previousPrice`;
    let queryString = new URLSearchParams();

    queryString.set('from', moment().subtract(29, 'days').format('YYYY-MM-DD'));
    queryString.set('to', moment().format('YYYY-MM-DD'));

    api = `${api}?${queryString.toString()}`;

    return resource.post(api, body, authHeader());
  },

  productforecast: (code, minprice) => {
    let api = `products/${code}/forecast`;
    let queryString = new URLSearchParams();

    if (!!minprice) {
      queryString.set('minprice', minprice);
    }

    api = `${api}?${queryString.toString()}`;

    return resource.get(api, authHeader());
  },

  ecommerce: (userId, shopId, from, to, limit) => {
    let api = `user/${userId}/shop/${shopId}/analytics/ecommerce`;
    let queryString = new URLSearchParams();

    if (!!from) {
      queryString.set('from', from);
    }

    if (!!to) {
      queryString.set('to', to);
    }

    if (!!limit) {
      queryString.set('limit', limit);
    }

    api = `${api}?${queryString.toString()}`;

    return resource.get(api, authHeader());
  },

  minsanAnalisys: (userId, shopId, body, from, to, limit) => {
    let api = `user/${userId}/shop/${shopId}/analytics/minsan_analisys`;
    let queryString = new URLSearchParams();

    if (!!from) {
      queryString.set('from', from);
    }

    if (!!to) {
      queryString.set('to', to);
    }

    if (!!limit) {
      queryString.set('limit', limit);
    }

    api = `${api}?${queryString.toString()}`;

    return resource.post(api, body, authHeader());
  },

  stats: (userId, shopId) => {
    let url = resource.get(`user/${userId}/shop/${shopId}/analytics/stats`, authHeader());
    return url;
  },

  statsHistory: (userId, shopId, from, to) => {
    let api = `user/${userId}/shop/${shopId}/analytics/statshistory`;
    let queryString = new URLSearchParams();

    if (!!from) {
      queryString.set('from', from);
    }

    if (!!to) {
      queryString.set('to', to);
    }

    api = `${api}?${queryString.toString()}`;
    return resource.get(api, authHeader());
  },

  getIncomesStatsPerc: (userId, shopId, from, to) => {
    let api = `user/${userId}/shop/${shopId}/analytics/incomesstats/perc`;
    let queryString = new URLSearchParams();

    if (!!from) {
      queryString.set('from', from);
    }

    if (!!to) {
      queryString.set('to', to);
    }

    api = `${api}?${queryString.toString()}`;
    return resource.get(api, authHeader());
  },

  getIncomesStatsEuro: (userId, shopId, from, to) => {
    let api = `user/${userId}/shop/${shopId}/analytics/incomesstats/euro`;
    let queryString = new URLSearchParams();

    if (!!from) {
      queryString.set('from', from);
    }

    if (!!to) {
      queryString.set('to', to);
    }

    api = `${api}?${queryString.toString()}`;
    return resource.get(api, authHeader());
  },

  priceClickStats: (userId, shopId) => {
    return resource.get(`user/${userId}/shop/${shopId}/analytics/priceclickstats`, authHeader());
  },
};
