import hash from 'object-hash';
import moment from 'moment';

let cacheData = {};

export default class Resource {
  constructor(url) {
    this.url = url;
  }

  async get(path, headers, cache = true) {
    return await this.raw('get', path, null, headers, cache);
  }

  async put(path, body, headers, cache = false) {
    return await this.raw('put', path, body, headers, cache);
  }

  async post(path, body, headers, cache = false) {
    return await this.raw('post', path, body, headers, cache);
  }

  async delete(path, headers, cache = false) {
    return await this.raw('delete', path, null, headers, cache);
  }

  async raw(method, path, body, headers = {}, cacheActive = false) {
    const requestOptions = {
      method,
      headers: Object.assign({ 'Content-Type': 'application/json' }, headers),
      body: body ? JSON.stringify(body) : null,
    };

    let requestHash = hash({
      url: `${this.url}/${path}`,
      requestOptions,
    });

    if (!!cacheData) {
      Object.keys(cacheData).map((hash) => {
        let cache = cacheData[hash];
        if (moment().isAfter(cache.expireAt)) {
          delete cacheData[hash];
        }
        return hash;
      });
    }

    if (!!cacheActive && !!cacheData && !!cacheData[requestHash] && !!cacheData[requestHash].response) {
      cacheData[requestHash].expireAt = moment().add(2, 'minute');
      return cacheData[requestHash].response;
    }

    let response = null;
    try {
      response = await fetch(`${this.url}/${path}`, requestOptions);
      response = await this.handleResponse(response);
      console.log('response raw', response);
    } catch (error) {
      //alert("Si è verificato un errore di connessione a "+this.url);
      console.error(error);
    }

    if (!!cacheActive) {
      cacheData[requestHash] = {
        response: response,
        expireAt: moment().add(2, 'minute'),
      };
    }

    return response;
  }

  async handleError(response) {
    const error = response.statusText;
    let message;

    try {
      message = JSON.parse(await response.text());
    } catch (error) {
      message = undefined;
    }

    return {
      error: error,
      status: response.status,
      message,
    };
  }

  async handleResponse(response) {
    switch (response.status) {
      case 204:
        return Promise.resolve(true);

      case 401:
        return response.text().then((text) => {
          try {
            window.location.replace('/user/login');
          } catch (error) {
            console.error(error);
          }
          return Promise.reject(text);
        });

      case 200:
      default:
        if (!response.ok) {
          return Promise.reject(await this.handleError(response));
        }
        return response.text().then((text) => {
          try {
            if (text.length) {
              text = JSON.parse(text);
            }
          } catch (error) {
            console.log('Response is not a JSON.');
          }
          return text;
        });
    }
  }
}
